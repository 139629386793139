import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { VueCookieNext } from 'vue-cookie-next'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import lazyPlugin from 'vue3-lazy'

const loadimage = require('./assets/loader.gif')
// http://190.92.179.199/NashoofAPI/APIs/GetContentByCategory.php?cat_id=1&LIMIT=15&OFFSET=0

axios.defaults.baseURL = 'https://nashoof.sd.zain.com/NashoofAP_I/APIs/'
// axios.defaults.baseURL = 'http://190.92.179.199/NashoofAPI/APIs/'
createApp(App)
     .use(router)
     .use(VueAxios, axios)
     .use(VueCookieNext)
     .use(Toast)
     .use(VueLoading, {
          // props
          color: '#1a8287',
          loader:'dots'
      })
      .use(lazyPlugin, {
          preLoad: 1.3,
          loading: loadimage,
          attempt: 1
          // loading: 'loading.png',
          // error: 'error.png'
        })
     .mount('#app')
