<template>
    <!-- header (relative style) -->
    <header class="header header--static">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="header__content">
                        <button class="header__menu" type="button">
                            <span></span>
                            <span></span>
                            <span></span>
                        </button>
                        <router-link to="/" class="header__logo">
                            <!-- <span class="img" style="font-family: 'Alfa Slab One', cursive;color: #fff;font-size: 21px;">NASHOOF</span>
                                 -->

                            <img style="z-index: -1;" src="http://nashoof-sd.com/assets/img/Nashoof.png" alt="">

                        </router-link>
                        <ul class="header__nav">
                            <li class="header__nav-item">
                                <router-link to="/" class="header__nav-link" role="button" id="dropdownMenu2"
                                    aria-expanded="false">
                                    <svg class="svg-icon" style="margin-right:0px;width:17px;margin-left: 5px;"
                                        viewBox="0 0 20 20">
                                        <path fill="#fff"
                                            d="M18.121,9.88l-7.832-7.836c-0.155-0.158-0.428-0.155-0.584,0L1.842,9.913c-0.262,0.263-0.073,0.705,0.292,0.705h2.069v7.042c0,0.227,0.187,0.414,0.414,0.414h3.725c0.228,0,0.414-0.188,0.414-0.414v-3.313h2.483v3.313c0,0.227,0.187,0.414,0.413,0.414h3.726c0.229,0,0.414-0.188,0.414-0.414v-7.042h2.068h0.004C18.331,10.617,18.389,10.146,18.121,9.88 M14.963,17.245h-2.896v-3.313c0-0.229-0.186-0.415-0.414-0.415H8.342c-0.228,0-0.414,0.187-0.414,0.415v3.313H5.032v-6.628h9.931V17.245z M3.133,9.79l6.864-6.868l6.867,6.868H3.133z">
                                        </path>
                                    </svg>
                                    الصفحة الرئيسية
                                </router-link>
                            </li>
                            <li class="header__nav-item">
                                <a class="dropdown-item" href="#" role="button" id="dropdownMenuSub" data-toggle="dropdown"
                                    aria-haspopup="true" aria-expanded="false">
                                    <svg class="svg-icon" viewBox="0 0 20 20"
                                        style="margin-right:0px;width:17px;margin-left: 5px;">
                                        <path fill="#fff" d="M7.228,11.464H1.996c-0.723,0-1.308,0.587-1.308,1.309v5.232c0,0.722,0.585,1.308,1.308,1.308h5.232
                                              c0.723,0,1.308-0.586,1.308-1.308v-5.232C8.536,12.051,7.95,11.464,7.228,11.464z M7.228,17.351c0,0.361-0.293,0.654-0.654,0.654
                                              H2.649c-0.361,0-0.654-0.293-0.654-0.654v-3.924c0-0.361,0.292-0.654,0.654-0.654h3.924c0.361,0,0.654,0.293,0.654,0.654V17.351z
                                              M17.692,11.464H12.46c-0.723,0-1.308,0.587-1.308,1.309v5.232c0,0.722,0.585,1.308,1.308,1.308h5.232
                                              c0.722,0,1.308-0.586,1.308-1.308v-5.232C19,12.051,18.414,11.464,17.692,11.464z M17.692,17.351c0,0.361-0.293,0.654-0.654,0.654
                                              h-3.924c-0.361,0-0.654-0.293-0.654-0.654v-3.924c0-0.361,0.293-0.654,0.654-0.654h3.924c0.361,0,0.654,0.293,0.654,0.654V17.351z
                                              M7.228,1H1.996C1.273,1,0.688,1.585,0.688,2.308V7.54c0,0.723,0.585,1.308,1.308,1.308h5.232c0.723,0,1.308-0.585,1.308-1.308
                                              V2.308C8.536,1.585,7.95,1,7.228,1z M7.228,6.886c0,0.361-0.293,0.654-0.654,0.654H2.649c-0.361,0-0.654-0.292-0.654-0.654V2.962
                                              c0-0.361,0.292-0.654,0.654-0.654h3.924c0.361,0,0.654,0.292,0.654,0.654V6.886z M17.692,1H12.46c-0.723,0-1.308,0.585-1.308,1.308
                                              V7.54c0,0.723,0.585,1.308,1.308,1.308h5.232C18.414,8.848,19,8.263,19,7.54V2.308C19,1.585,18.414,1,17.692,1z M17.692,6.886
                                              c0,0.361-0.293,0.654-0.654,0.654h-3.924c-0.361,0-0.654-0.292-0.654-0.654V2.962c0-0.361,0.293-0.654,0.654-0.654h3.924
                                              c0.361,0,0.654,0.292,0.654,0.654V6.886z"></path>
                                    </svg>
                                    التصنيفات
                                </a>
                                <ul class="dropdown-menu header__nav-menu" aria-labelledby="dropdownMenuSub">
                                    <li class="header__nav-item">
                                        <router-link to="Health" class="header__nav-link">صحة</router-link>
                                    </li>
                                    <li class="header__nav-item">
                                        <router-link to="Education" class="header__nav-link">تعليم</router-link>
                                    </li>
                                    <li class="header__nav-item">
                                        <router-link to="Low" class="header__nav-link">قانون</router-link>
                                    </li>
                                    <li class="header__nav-item">
                                        <router-link to="Art" class="header__nav-link">فن ودراما</router-link>
                                    </li>
                                    <!-- <li class="header__nav-item">
                                            <router-link to="Comedy" class="header__nav-link">كوميديا</router-link>
                                        </li>
                                        <li class="header__nav-item">
                                            <router-link to="Sport" class="header__nav-link">رياضة</router-link>
                                        </li> -->
                                </ul>
                            </li>
                            <li class="header__nav-item" v-if="showHeader">
                                


                                <a class="dropdown-item" href="#" role="button" id="dropdownMenuSub" data-toggle="dropdown"
                                    aria-haspopup="true" aria-expanded="false">
                                    <svg class="svg-icon" style="margin-right:0px;width:17px;margin-left: 5px;"
                                        viewBox="0 0 20 20">
                                        <path fill="#fff"
                                            d="M13.962,8.885l-3.736,3.739c-0.086,0.086-0.201,0.13-0.314,0.13S9.686,12.71,9.6,12.624l-3.562-3.56C5.863,8.892,5.863,8.611,6.036,8.438c0.175-0.173,0.454-0.173,0.626,0l3.25,3.247l3.426-3.424c0.173-0.172,0.451-0.172,0.624,0C14.137,8.434,14.137,8.712,13.962,8.885 M18.406,10c0,4.644-3.763,8.406-8.406,8.406S1.594,14.644,1.594,10S5.356,1.594,10,1.594S18.406,5.356,18.406,10 M17.521,10c0-4.148-3.373-7.521-7.521-7.521c-4.148,0-7.521,3.374-7.521,7.521c0,4.147,3.374,7.521,7.521,7.521C14.148,17.521,17.521,14.147,17.521,10">
                                        </path>
                                    </svg>
                                    {{ $cookie.getCookie("mdn") }}

                                </a>
                                <ul class="dropdown-menu header__nav-menu" aria-labelledby="dropdownMenuSub">
                                    <!-- <li><a href="#">حسابي</a></li> -->
                                    <li v-if="categSaha == 1">
                                        <a style="color:red;" @click.prevent="unsubscribe(6256116771, 'صحة')">
                                            إلغــاء خدمة صحة

                                            <!-- <svg style="margin-right: 46%;width: 22px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path style="stroke: #d5bcbc;fill: #f80000;" d="M20,12a1,1,0,0,0-1-1H11.41l2.3-2.29a1,1,0,1,0-1.42-1.42l-4,4a1,1,0,0,0-.21.33,1,1,0,0,0,0,.76,1,1,0,0,0,.21.33l4,4a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L11.41,13H19A1,1,0,0,0,20,12ZM17,2H7A3,3,0,0,0,4,5V19a3,3,0,0,0,3,3H17a3,3,0,0,0,3-3V16a1,1,0,0,0-2,0v3a1,1,0,0,1-1,1H7a1,1,0,0,1-1-1V5A1,1,0,0,1,7,4H17a1,1,0,0,1,1,1V8a1,1,0,0,0,2,0V5A3,3,0,0,0,17,2Z"></path></svg> -->
                                        </a>
                                    </li>
                                    <li v-if="categEducation == 1">
                                        <a style="color:red;" @click.prevent="unsubscribe(9385777625, 'تعليم')">
                                            إلغــاء خدمة تعليم

                                            <!-- <svg style="margin-right: 46%;width: 22px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path style="stroke: #d5bcbc;fill: #f80000;" d="M20,12a1,1,0,0,0-1-1H11.41l2.3-2.29a1,1,0,1,0-1.42-1.42l-4,4a1,1,0,0,0-.21.33,1,1,0,0,0,0,.76,1,1,0,0,0,.21.33l4,4a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L11.41,13H19A1,1,0,0,0,20,12ZM17,2H7A3,3,0,0,0,4,5V19a3,3,0,0,0,3,3H17a3,3,0,0,0,3-3V16a1,1,0,0,0-2,0v3a1,1,0,0,1-1,1H7a1,1,0,0,1-1-1V5A1,1,0,0,1,7,4H17a1,1,0,0,1,1,1V8a1,1,0,0,0,2,0V5A3,3,0,0,0,17,2Z"></path></svg> -->
                                        </a>
                                    </li>
                                    <li v-if="categLow == 1">
                                        <a style="color:red;" @click.prevent="unsubscribe(6485488936, 'قانون')">
                                            إلغــاء خدمة قانون

                                            <!-- <svg style="margin-right: 46%;width: 22px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path style="stroke: #d5bcbc;fill: #f80000;" d="M20,12a1,1,0,0,0-1-1H11.41l2.3-2.29a1,1,0,1,0-1.42-1.42l-4,4a1,1,0,0,0-.21.33,1,1,0,0,0,0,.76,1,1,0,0,0,.21.33l4,4a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L11.41,13H19A1,1,0,0,0,20,12ZM17,2H7A3,3,0,0,0,4,5V19a3,3,0,0,0,3,3H17a3,3,0,0,0,3-3V16a1,1,0,0,0-2,0v3a1,1,0,0,1-1,1H7a1,1,0,0,1-1-1V5A1,1,0,0,1,7,4H17a1,1,0,0,1,1,1V8a1,1,0,0,0,2,0V5A3,3,0,0,0,17,2Z"></path></svg> -->
                                        </a>
                                    </li>
                                    <li v-if="categArt == 1">
                                        <a style="color:red;" @click.prevent="unsubscribe(6394667334, 'فن و دراما')">
                                            إلغــاء فن و دراما

                                            <!-- <svg style="margin-right: 46%;width: 22px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path style="stroke: #d5bcbc;fill: #f80000;" d="M20,12a1,1,0,0,0-1-1H11.41l2.3-2.29a1,1,0,1,0-1.42-1.42l-4,4a1,1,0,0,0-.21.33,1,1,0,0,0,0,.76,1,1,0,0,0,.21.33l4,4a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L11.41,13H19A1,1,0,0,0,20,12ZM17,2H7A3,3,0,0,0,4,5V19a3,3,0,0,0,3,3H17a3,3,0,0,0,3-3V16a1,1,0,0,0-2,0v3a1,1,0,0,1-1,1H7a1,1,0,0,1-1-1V5A1,1,0,0,1,7,4H17a1,1,0,0,1,1,1V8a1,1,0,0,0,2,0V5A3,3,0,0,0,17,2Z"></path></svg> -->
                                        </a>
                                    </li>
                                </ul>
                                
                            </li>

                            <li class="header__nav-item" v-else>
                                <a class="dropdown-item" href="#" role="button" id="dropdownMenuSub" data-toggle="dropdown"
                                    aria-haspopup="true" aria-expanded="false">

                                    <!-- <svg class="svg-icon" style="margin-right:0px;width:17px;margin-left: 5px;" viewBox="0 0 20 20">
							               <path fill="#fff" d="M13.962,8.885l-3.736,3.739c-0.086,0.086-0.201,0.13-0.314,0.13S9.686,12.71,9.6,12.624l-3.562-3.56C5.863,8.892,5.863,8.611,6.036,8.438c0.175-0.173,0.454-0.173,0.626,0l3.25,3.247l3.426-3.424c0.173-0.172,0.451-0.172,0.624,0C14.137,8.434,14.137,8.712,13.962,8.885 M18.406,10c0,4.644-3.763,8.406-8.406,8.406S1.594,14.644,1.594,10S5.356,1.594,10,1.594S18.406,5.356,18.406,10 M17.521,10c0-4.148-3.373-7.521-7.521-7.521c-4.148,0-7.521,3.374-7.521,7.521c0,4.147,3.374,7.521,7.521,7.521C14.148,17.521,17.521,14.147,17.521,10"></path>
						                </svg> -->
                                </a>
                                <!-- <ul class="dropdown-menu header__nav-menu" aria-labelledby="dropdownMenuSub">
                                        <li><router-link to="Login">تسجيل دخول</router-link></li>
                                         <li>
                                            <a style="color:red;" href="MiddleWare/UnSub.php?msisdn=' . $msisdn . '&SUBCP=' . $articles->product_code . '">
                                            اشتراك
                                                <svg style="margin-right: 46%;width: 22px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path style="stroke: #d5bcbc;fill: #f80000;" d="M20,12a1,1,0,0,0-1-1H11.41l2.3-2.29a1,1,0,1,0-1.42-1.42l-4,4a1,1,0,0,0-.21.33,1,1,0,0,0,0,.76,1,1,0,0,0,.21.33l4,4a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L11.41,13H19A1,1,0,0,0,20,12ZM17,2H7A3,3,0,0,0,4,5V19a3,3,0,0,0,3,3H17a3,3,0,0,0,3-3V16a1,1,0,0,0-2,0v3a1,1,0,0,1-1,1H7a1,1,0,0,1-1-1V5A1,1,0,0,1,7,4H17a1,1,0,0,1,1,1V8a1,1,0,0,0,2,0V5A3,3,0,0,0,17,2Z"></path></svg>'
                                            </a>
                                        </li> 
                                    </ul> -->
                            </li>

                            <li class="header__nav-item">
                                <router-link to="/Contact" class="header__nav-link" role="button" id="dropdownMenu2"
                                    aria-expanded="false">
                                    <i class="bi bi-telephone-fill" style="margin-left:5px;"></i>
                                    <!-- <svg class="svg-icon" style="margin-right:0px;width:17px;margin-left: 5px;"
                                        viewBox="0 0 20 20">
                                        <path fill="#fff"
                                            d="M18.121,9.88l-7.832-7.836c-0.155-0.158-0.428-0.155-0.584,0L1.842,9.913c-0.262,0.263-0.073,0.705,0.292,0.705h2.069v7.042c0,0.227,0.187,0.414,0.414,0.414h3.725c0.228,0,0.414-0.188,0.414-0.414v-3.313h2.483v3.313c0,0.227,0.187,0.414,0.413,0.414h3.726c0.229,0,0.414-0.188,0.414-0.414v-7.042h2.068h0.004C18.331,10.617,18.389,10.146,18.121,9.88 M14.963,17.245h-2.896v-3.313c0-0.229-0.186-0.415-0.414-0.415H8.342c-0.228,0-0.414,0.187-0.414,0.415v3.313H5.032v-6.628h9.931V17.245z M3.133,9.79l6.864-6.868l6.867,6.868H3.133z">
                                        </path>
                                    </svg> -->
                                     اتصل بنا
                                </router-link>
                            </li>
                        </ul>
                        <div class="header__actions" style="margin-left: 50px;">
                            <form v-on:submit.prevent="SearchData()" class="header__form">
                                <input class="header__form-input" v-model="search" type="text" placeholder="بحث ...">
                                <button class="header__form-btn" type="submit"><svg xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24">
                                        <path
                                            d="M21.71,20.29,18,16.61A9,9,0,1,0,16.61,18l3.68,3.68a1,1,0,0,0,1.42,0A1,1,0,0,0,21.71,20.29ZM11,18a7,7,0,1,1,7-7A7,7,0,0,1,11,18Z" />
                                    </svg></button>
                                <button type="button" class="header__form-close"><svg width="20" height="20"
                                        viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M14.3345 0.000183105H5.66549C2.26791 0.000183105 0.000488281 2.43278 0.000488281 5.91618V14.0842C0.000488281 17.5709 2.26186 20.0002 5.66549 20.0002H14.3335C17.7381 20.0002 20.0005 17.5709 20.0005 14.0842V5.91618C20.0005 2.42969 17.7383 0.000183105 14.3345 0.000183105ZM5.66549 1.50018H14.3345C16.885 1.50018 18.5005 3.23515 18.5005 5.91618V14.0842C18.5005 16.7653 16.8849 18.5002 14.3335 18.5002H5.66549C3.11525 18.5002 1.50049 16.7655 1.50049 14.0842V5.91618C1.50049 3.23856 3.12083 1.50018 5.66549 1.50018ZM7.07071 7.0624C7.33701 6.79616 7.75367 6.772 8.04726 6.98988L8.13137 7.06251L9.99909 8.93062L11.8652 7.06455C12.1581 6.77166 12.6329 6.77166 12.9258 7.06455C13.1921 7.33082 13.2163 7.74748 12.9984 8.04109L12.9258 8.12521L11.0596 9.99139L12.9274 11.8595C13.2202 12.1524 13.2202 12.6273 12.9273 12.9202C12.661 13.1864 12.2443 13.2106 11.9507 12.9927L11.8666 12.9201L9.99898 11.052L8.13382 12.9172C7.84093 13.2101 7.36605 13.2101 7.07316 12.9172C6.80689 12.6509 6.78269 12.2343 7.00054 11.9407L7.07316 11.8566L8.93843 9.99128L7.0706 8.12306C6.77774 7.83013 6.77779 7.35526 7.07071 7.0624Z" />
                                    </svg></button>
                            </form>
                            <button class="header__search" type="button" style="margin-left: 88px;">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                    <path
                                        d="M21.71,20.29,18,16.61A9,9,0,1,0,16.61,18l3.68,3.68a1,1,0,0,0,1.42,0A1,1,0,0,0,21.71,20.29ZM11,18a7,7,0,1,1,7-7A7,7,0,0,1,11,18Z" />
                                </svg>
                            </button>

                            <loading v-model:active="visible" :can-cancel="true"></loading>
                            &nbsp;
                                &nbsp;

                                <router-link v-if="showHeader" to="/Profile" class="header__profile pointer" role="button" 
                                    aria-expanded="false">
                                    <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M15.71,12.71a6,6,0,1,0-7.42,0,10,10,0,0,0-6.22,8.18,1,1,0,0,0,2,.22,8,8,0,0,1,15.9,0,1,1,0,0,0,1,.89h.11a1,1,0,0,0,.88-1.1A10,10,0,0,0,15.71,12.71ZM12,12a4,4,0,1,1,4-4A4,4,0,0,1,12,12Z"></path></svg> -->
                                    <i class="bi bi-person-square" ></i>
                                </router-link>

                            <a href="https://sd.zain.com/" class="header__user pointer">
                                <img style="width: 100px;z-index: -1;"
                                    src="https://nashoof.sd.zain.com/assets/img/logoSvg.svg" alt="">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
    <!-- end header -->
</template>

<script>
import axios from "axios";
import { useToast } from "vue-toastification";
import { useRoute } from 'vue-router';
import { computed } from 'vue';
import config from '@/Api/config';

export default {
    name: 'Header',
    data() {
        return {
            categSaha: null,
            categEducation: null,
            categLow: null,
            categArt: null,
            search: "",
        };
    },

    setup() {
        const route = useRoute();

        const path = computed(() => route.path).value
        if (path.includes(".php")) {

            this.$router.push("/");
            this.$router.go()

        }
    },

    beforeCreate: function () {
        if (this.$cookie.isCookieAvailable("mdn")) {
            axios.get(
                "ProductCodeLogin.php?msisdn=" + this.$cookie.getCookie("mdn")
                , {
                    headers: {
                        'Authorization': config.authHeader,
                    },
                }).then((response) => {
                    if (response.data.status == 1) {
                        return;
                    } else {
                        this.$cookie.removeCookie("mdn");
                        this.$cookie.removeCookie("Content_id");
                        this.$cookie.removeCookie("minutes");
                        this.$cookie.removeCookie("Catogery_id");
                        this.$cookie.removeCookie("product_code");
                    }
                });
            this.showHeader = true;
        } else {
            this.showHeader = false;
        }
    },
    mounted() {
        if (this.$cookie.isCookieAvailable("mdn")) {
            axios.get("CheckLoginByProduct_Code.php?msisdn=" + this.$cookie.getCookie("mdn") + "&product_code=6256116771", {
                headers: {
                    'Authorization': config.authHeader,
                },
            })
            .then((response) => {this.categArt = response.data.status;
                    this.$cookie.setCookie('Health', response.data.status, 60 * 300);

        });
            axios.get("CheckLoginByProduct_Code.php?msisdn=" + this.$cookie.getCookie("mdn") + "&product_code=9385777625", {
                headers: {
                    'Authorization': config.authHeader,
                },
            })
            .then((response) => {this.categArt = response.data.status;
                    this.$cookie.setCookie('EDU', response.data.status, 60 * 300);

        });
            axios.get("CheckLoginByProduct_Code.php?msisdn=" + this.$cookie.getCookie("mdn") + "&product_code=6485488936", {
                headers: {
                    'Authorization': config.authHeader,
                },
            })
            .then((response) => {this.categArt = response.data.status;
                    this.$cookie.setCookie('Law', response.data.status, 60 * 300);

        });
            axios.get("CheckLoginByProduct_Code.php?msisdn=" + this.$cookie.getCookie("mdn") + "&product_code=6394667334", {
                headers: {
                    'Authorization': config.authHeader,
                },
            })
                .then((response) => {this.categArt = response.data.status;
                    this.$cookie.setCookie('Drama', response.data.status, 60 * 300);

        });
        }

        let externalScript = document.createElement('script')
        externalScript.setAttribute('src', 'assets/js/main.js')
        document.head.appendChild(externalScript)


        //   let externalScript2 = document.createElement('script')
        // externalScript2.setAttribute('src', 'assets/js/slider-radio.js')
        // document.head.appendChild(externalScript2)
    },
    methods: {
        exitUser: function () {
            let loader = this.$loading.show({
                // Optional parameters
                container: this.oldNum ? null : this.$refs.formContainer,
                // canCancel: true,
                // onCancel: this.onCancel,
            });
            // simulate AJAX
            setTimeout(() => loader.hide(), 2 * 1000)


            const toast = useToast();
            toast.success("تم تسجيل الخروج من منصة نشوف", {
                timeout: 3000
            });
            this.$cookie.keys().forEach(cookie => this.$cookie.removeCookie(cookie))
            this.$cookie.removeCookie("mdn");
            this.$cookie.removeCookie("Content_id");
            this.$cookie.removeCookie("minutes");
            this.$cookie.removeCookie("Catogery_id");
            this.$cookie.removeCookie("product_code");


            if (this.$cookie.isCookieAvailable("minutes")) {
                return

            } else {
                this.$router.push("/");
                this.$router.go()
            }
        },
        unsubscribe: function (cat, cat_name) {

            let loader = this.$loading.show({
                // Optional parameters
                container: this.oldNum ? null : this.$refs.formContainer,
                // canCancel: true,
                // onCancel: this.onCancel,
            });
            // simulate AJAX
            setTimeout(() => loader.hide(), 5 * 1000)

            const toast = useToast();
            axios
                .get("DSPUnsubscribe.php?msisdn=" + this.$cookie.getCookie("mdn") + "&product_code=" + cat + "", {
                    headers: {
                        'Authorization': config.authHeader,
                    },
                })
                .then((response) => {
                    if (response.data.status == 1) {
                        //   this.$cookie.keys().forEach(cookie => this.$cookie.removeCookie(cookie))
                        //   this.$cookie.removeCookie("mdn");
                        this.$cookie.removeCookie("Content_id");
                        this.$cookie.removeCookie("minutes");
                        this.$cookie.removeCookie("Catogery_id");
                        this.$cookie.removeCookie("product_code");

                        toast.success("لقد تم الغاء اشتراكك من " + cat_name + " نشوف ", {
                            timeout: 5000
                        })

                        this.$router.push("/");
                        this.$router.go()
                    } else {
                        toast.error("عفوا ليست لديك اشتراك في خدمة نشوف", {
                            timeout: 5000
                        })
                    }
                })
        },
        // unsubscribe () {
        //     const toast = useToast();
        //      axios
        //     .post("DSPUnsubscribe.php?msisdn="+this.$cookie.getCookie("mdn")+"&product_code="+this.$cookie.getCookie("product_code")+"")
        //     .then((response) => {
        //       if (response.data.status == 1) {
        //         alert("DSP Unsubscribe Seccess");
        //         this.$cookie.removeCookie("mdn");
        //         this.$cookie.removeCookie("Content_id");
        //         this.$cookie.removeCookie("minutes");
        //         this.$cookie.removeCookie("Catogery_id");
        //         this.$cookie.removeCookie("product_code");
        //         this.$router.push("/");
        //         this.$router.go()
        //       } else { 
        //         toast.success("عفوا ليست لديك اشتراك في خدمة نشوف", {
        //            timeout: 2000
        //         })
        //       }
        //     })
        // },

        SearchData() {
            const toast = useToast();

            // Define the regex pattern to allow only Arabic and English letters, and spaces
            const validTextRegex = /^[A-Za-z\u0600-\u06FF\s]*$/;

            if (validTextRegex.test(this.search)) {
                this.$router.push({ name: "Search", params: { search: this.search } });
                // this.$cookie.setCookie('search', this.search);
            } else {
                // If invalid, remove the non-alphabetic characters and update search query
                this.search  = this.search.replace(/[^A-Za-z\u0600-\u06FF\s]/g, '');
                toast.info("لا يوجد نتائج للبحث");  // Mark as invalid
            }
        }
    },
}
</script>

<style scoped>a:hover {
    color: #0056b3;

}

.header__nav-menu a:hover {
    color: #87a1ef;
}

.pointer {
    cursor: pointer;
}</style>