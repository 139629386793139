<template>
  <AppHeader />
  <Suspense>
    <template #default>
       <SeriesContent />
    </template>
    <template #fallback>
       <Loding />
    </template>
  </Suspense>
  <AppFooter />
</template>

<script>
import { defineAsyncComponent, Suspense } from "vue";
import Loding from "@/components/Include/Loding.vue";
import AppHeader from "@/components/Include/Header.vue";
import AppFooter from "@/components/Include/Footer.vue";
const SeriesContent = defineAsyncComponent(() =>
  import('@/components/Categorys/SeriesContents.vue')
)
export default {
   name: 'SeriesContents',
   components: {
    Suspense,
    Loding,
    AppHeader,
    AppFooter,
    SeriesContent
  },
}
</script>

<style>

</style>