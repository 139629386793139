<template>
  <Header />
  <Suspense>
    <template #default>
      <Logins />
    </template>
    <template #fallback>
      <Loding />
    </template>
  </Suspense>
  <Footer />
</template>

<script>
// @ is an alias to /src
import { Suspense } from "vue";
import Loding from "@/components/Include/Loding.vue";
import Header from "@/components/Include/Header.vue";
import Footer from "@/components/Include/Footer.vue";
import Logins from "@/components/Users/Profiles.vue";

export default {
  name: "Login",
  components: {
    Suspense,
    Loding,
    Header,
    Footer,
    Logins,
    
  },
};
</script>