import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
// import Trailer from '../components/Trailer.vue'
// import Subscribe from '../components/Subscribe.vue'
// import Signup from '../components/Signup.vue'
// import Virefy from '../components/Virefy.vue'
// import Content from '../components/Content.vue'
import Search from '../components/Search.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  // {
  //   path: '/trailer',
  //   name: 'Trailer',
  //   component: Trailer,
  //   props: true,
  // },
  // {
  //   path: '/subscribe/:cat_id',
  //   name: 'Subscribe',
  //   component: Subscribe,
  //   props: true,
  // },
  // {
  //   path: '/signup',
  //   name: 'Signup',
  //   component: Signup,
  //   props: true,
  // },
  // {
  //   path: '/virefy',
  //   name: 'Virefy',
  //   component: Virefy,
  //   props: true,
  // },
  // {
  //   path: '/content',
  //   name: 'Content',
  //   component: Content,
  // },
  {
    path: '/search/:search',
    name: 'Search',
    component: Search,
    props: true,
  },
  {
    path: '/trailer',
    name: 'Trailer',
    // route level code-splitting
    // this generates a separate chunk (trailer.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "trailer" */ '../views/page/Trailer.vue')
  },
 
  {
    path: '/content',
    name: 'Content',
    // route level code-splitting
    // this generates a separate chunk (content.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "content" */ '../views/page/Content.vue')
  },
  {
    path: '/subscribe',
    name: 'Subscribe',
    // route level code-splitting
    // this generates a separate chunk (subscribe.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "subscribe" */ '../views/user/Subscribe.vue')
  },
  {
    path: '/signup',
    name: 'Signup',
    // route level code-splitting
    // this generates a separate chunk (signup.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "signup" */ '../views/user/Signup.vue')
  },
  {
    path: '/virefy',
    name: 'Virefy',
    // route level code-splitting
    // this generates a separate chunk (virefy.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "virefy" */ '../views/user/Virefy.vue')
  },
  {
    path: '/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (login.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "login" */ '../views/user/Login.vue')
  },
  {
    path: '/health',
    name: 'Health',
    // route level code-splitting
    // this generates a separate chunk (health.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "health" */ '../views/category/Health.vue')
  },
  {
    path: '/education',
    name: 'Education',
    // route level code-splitting
    // this generates a separate chunk (education.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "education" */ '../views/category/Education.vue')
  },
  {
    path: '/low',
    name: 'Low',
    // route level code-splitting
    // this generates a separate chunk (low.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "low" */ '../views/category/Low.vue')
  },
  {
    path: '/art',
    name: 'Art',
    // route level code-splitting
    // this generates a separate chunk (art.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "art" */ '../views/category/Art.vue')
  },
  {
    path: '/comedy',
    name: 'Comedy',
    // route level code-splitting
    // this generates a separate chunk (comedy.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "comedy" */ '../views/category/Comedy.vue')
  },
  {
    path: '/sport',
    name: 'Sport',
    // route level code-splitting
    // this generates a separate chunk (sport.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "sport" */ '../views/category/Sport.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    // route level code-splitting
    // this generates a separate chunk (login.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "login" */ '../views/page/Contact.vue')
  },
  {
    path: '/profile',
    name: 'Profile',
    // route level code-splitting
    // this generates a separate chunk (login.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "login" */ '../views/user/Profile.vue')
  }
  ,
  {
    path: '/artist',
    name: 'Artist',
    component: () => import(/* webpackChunkName: "art" */ '../views/category/Artist.vue')
  },
  {
    path: '/artistContent',
    name: 'ArtistContent',
    component: () => import(/* webpackChunkName: "art" */ '../views/category/ArtistContent.vue')
  }
  ,
  {
    path: '/seriesContent',
    name: 'SeriesContent',
    component: () => import(/* webpackChunkName: "art" */ '../views/category/SeriesContent.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
}
})

export default router
