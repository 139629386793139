<template>
    <Header />
    <Suspense>
      <template #default>
        <Contents />
      </template>
      <template #fallback>
        <Loding />
      </template>
    </Suspense>
    <Footer />
  </template>
  
  <script>
  // @ is an alias to /src
  import { Suspense } from "vue";
  import Loding from "@/components/Include/Loding.vue";
  import Header from "@/components/Include/Header.vue";
  import Footer from "@/components/Include/Footer.vue";
  import Contents from "@/components/Pages/Contacts.vue";
  
  export default {
    name: "Content",
    components: {
      Suspense,
      Loding,
      Header,
      Footer,
      Contents,
      
    },
  };
  </script>